import React from 'react'
import GateKeeper from "../components/GateKeeper";
import HomePageComponent from '../components/homepage'

import "../components/Layout/layout.css";

if (typeof window !== "undefined") {
  var accessKeyValue = localStorage.getItem('GOLF4CHANGE_ACCESS_KEY');
}



export default function Index() {

  if (accessKeyValue == "ABC_123") {
    return (
      <>
      <HomePageComponent />
      
      </>
    )
  }
  else {
    return (
      <>
      <div className="elseHome">
      
      
      <HomePageComponent />
      <GateKeeper />
      </div>
      
      </>
    );
  }



}