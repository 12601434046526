import React from "react"
import { Link } from "gatsby"
import Helmet from 'react-helmet'


function gateKeeper(event) {
  let detectInput = document.querySelector('#input');
  if (detectInput.value === 'GFC2021') {
    localStorage.setItem('GOLF4CHANGE_ACCESS_KEY', "");
    console.log("GOLF4CHANGE_ACCESS_KEY " + detectInput.value)
    localStorage.setItem('GOLF4CHANGE_ACCESS_KEY', detectInput.value);

  }
  else {
    event.preventDefault()
    alert('Wrong Access Key, please contact gfcinfo@golfforchange.com')

  }
}

const GateKeeper = () => (

  <div className="gateKeeper">
      <div className="gateKeeper_input_container"> 

      <div className="gateKeeper_input">
        <div className="input_copy"><h3>Enter Access Key</h3></div>

        <input id="input"></input>
        <Link className="gateKeeper_link" to="/home" onClick={gateKeeper}>Go</Link>
      </div>
      </div>
    </div>

)


export default GateKeeper